@keyframes bounceLogo {
	0% {
		width: 32px;
	}
	100% {
		width: 64px;
	}
}

.logo-shadow {
	content: '';
	display: block;
	width: 64px;
	height: 6px;
	margin-top: 10px;
	animation-duration: 0.5s;
	animation-iteration-count: infinite;
	animation-name: bounceLogo;
	animation-timing-function: cubic-bezier(1, 0.8, 0.5, 1);
	animation-direction: alternate;
	animation-fill-mode: none;
	animation-play-state: running;
	width: 100%;
	background: radial-gradient(
		ellipse at center,
		rgba(0, 0, 0, 0.1) 0,
		transparent 100%
	);
}

@primary-color: #66c94a;@link-color: #66c94a;@layout-header-background: #2d3446;