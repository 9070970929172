@import '~antd/dist/antd.less';

:root {
	--primary-color: #66c94a;
	--secondary-color: #ff5f2e;
	--border-color: #e9e9e9;
	--success-color: #56a902;
	--error-color: #e53a40;
	--yellow-color: #f9c00c;
	--gray-color: #797979;
	--text-color: #797979;
	--gray-dark-color: #788195;
	--gray-light-color: #e0e0e0;
	--border-color: #e0e0e0;
	--gray-light-light-color: #f5f5f5;
	--white-less-color: #fcfcfc;
	--blue-color: #01aada;
	--background-color: #f1f3f6;
	--dark-color: #2d3446;
	--primary-font: 'Roboto', sans-serif;
	--secondary-fonts: 'Nunito', cursive;
}

#root {
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
}

p {
	color: var(--text-color);
	font-size: 14px;
}

button.ant-btn {
	display: flex;
	align-items: center;
}

.ant-modal-confirm-btns {
	display: flex;
	width: 100%;
	justify-content: flex-end;
	padding-top: 12px;
}

.ant-modal-content .ant-modal-close-x {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ant-modal-footer {
	display: flex;
	width: 100%;
	justify-content: flex-end;
	padding-top: 12px;
}

.ant-modal-footer > div {
	display: flex;
	width: 100%;
	justify-content: flex-end;
}

.ant-picker-dropdown .ant-picker-body th {
	text-align: center;
	font-size: 14px;
}

.ant-picker-dropdown .ant-picker-body td {
	text-align: center;
	font-size: 14px;
}

.ant-input-search .ant-input-suffix {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ant-input-search .ant-input-group-addon button {
	justify-content: center;
}

.ant-badge-count p {
	color: white;
}

.ant-notification {
	.ant-notification-notice {
		border-radius: 8px;
	}

	.ant-notification-notice-message {
		font-family: var(--primary-font);
		font-size: 1rem;
	}

	.ant-notification-notice-icon {
		margin-top: 4px;
	}

	.ant-notification-notice-close {
		top: 20px;
	}
}

.ant-select-item-option-state {
	align-self: center;
}

.ant-select-selection-item-remove {
	align-self: center;
}

.ant-popover.list-players-repertory .ant-list-item-meta-content {
	width: 100%;
}

.ant-menu-item {
	display: flex;
	align-items: center;
}

.ant-page-header {
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 32px;
	.ant-page-header-heading-title {
		color: var(--gray-dark-color);
		padding-left: 16px;
		border-left: 3px solid var(--gray-dark-color);
		font-size: x-large;
	}
}

@primary-color: #66c94a;@link-color: #66c94a;@layout-header-background: #2d3446;